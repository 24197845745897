<template>
  <ProfileSettingsOverlayStyled>
    <HeaderStyled>
      {{ $tc('profile', 1) }}
    </HeaderStyled>
    <MainStyled>
      <label>{{ $tc('name', 1) }}</label>
      <input :disabled="true" :value="profile.name" />
      <label>{{ $tc('email', 1) }}</label>
      <input :disabled="true" :value="profile.email" />
      <label>{{ $tc('picture', 1) }}</label>
      <ImageUploader :single="true" :image="profile.picture" @uploaded="handleUpload" @disconnect="handleDisconnect" />
      <OrganizationsHeaderStyled v-if="organizations.length">
        {{ $t('titles.yourOrganizations') }}
      </OrganizationsHeaderStyled>
      <OrganizationSettings v-for="organization in organizations" :key="organization.id" :organization="organization" />
    </MainStyled>
    <FooterStyled> </FooterStyled>
  </ProfileSettingsOverlayStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import get from 'lodash/get'
import { ImageUploader } from '@common/components'

import OrganizationSettings from './ProfileSettingsOverlay/OrganizationSettings'

import PROFILE_QUERY from '#/graphql/profile/show.gql'

import {
  flexCenter,
  // flexColumns,
  input,
} from '@styles/mixins'

const ProfileSettingsOverlayStyled = styled('div')`
  display: grid;
  width: 100%;
  max-width: 400px;
  margin-top: 50px;
  grid-template-rows: 50px auto 50px;
  grid-template-columns: auto;
  grid-template-areas:
    'header'
    'main'
    'foooter';
`

const HeaderStyled = styled('div')`
  ${flexCenter}
  grid-area: header;
  padding: 1rem;
  font-size: 16px;
  @media (min-width: 768px) {
    font-size: 20px;
  }
`

const MainStyled = styled('div')`
  grid-area: main;
  display: grid;
  width: calc(100% - 2rem);
  padding: 1rem;
  grid-auto-rows: minmax(50px, auto);
  grid-template-columns: 100px auto;
  border-radius: 0.25rem;
  background: ${p => p.theme.colors.solidBG};
  > label {
    ${flexCenter}
  }
  > input {
    ${input}
    margin: .5rem 0;
  }
`

const FooterStyled = styled('div')`
  grid-area: main;
`

const OrganizationsHeaderStyled = styled('div')`
  ${flexCenter}
  font-size: 1rem;
  margin-top: 3rem;
  grid-column: span 2;
`

export default {
  components: {
    ProfileSettingsOverlayStyled,
    HeaderStyled,
    MainStyled,
    FooterStyled,
    ImageUploader,
    OrganizationSettings,
    OrganizationsHeaderStyled,
  },
  data() {
    return {
      profile: {
        name: '',
        email: '',
        picture: {
          id: null,
          url: '',
        },
      },
    }
  },
  computed: {
    organizations() {
      return get(this.profile, 'memberships', []).map(membership => membership.organization)
    },
  },
  methods: {
    async handleUpload(/*image*/) {
      // this.$set(this.profile, 'picture', image)
      // const res = await this.$apollo.mutate({
      //   mutation: UPDATE_PROFILE_PICTURE_MUTATION,
      //   variables: {
      //     imageID: image.id,
      //   },
      // })
      // const url = get(res, 'data.updateProfilePicture.picture.url')
      // if (url) {
      //   this.$apollo.queries.profile.refetch()
      // }
    },
    async handleDisconnect() {
      // await this.$apollo.mutate({
      //   mutation: DISCONNECT_PROFILE_PICTURE_MUTATION,
      // })
      // this.$apollo.queries.profile.refetch()
    },
  },
  apollo: {
    profile: {
      query: PROFILE_QUERY,
    },
  },
}
</script>
